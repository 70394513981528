<template>
  <section class="section container">
    <div class="columns">
      <div class="column">
        <h1 class="m-b-md">FAQ - Perguntas e respostas mais comuns</h1>
        <img
          class="m-b-md"
          src="@/assets/img/assbandf-banner-small.jpg"
          alt="Imagem faq"
        >
        <h3 class="m-b-md">ASSBAN Cursos Online</h3>
        <p
          class="m-b-md"
        >Visando o contínuo aprimoramento dos profissionais que atuam no âmbito do Sistema Financeiro Nacional ou em atividades conexas e correlatas, bem como a comodidade desses profissionais, a ASSBAN disponibiliza uma série de conteúdos on line desenvolvidos por especialistas altamente capacitados em suas respectivas áreas de atuação. São cursos, palestras e treinamentos com foco no aperfeiçoamento permanente das atividades profissionais, com ênfase em temas que potencializam o desempenho e abordam as mais novas tendências e atualidades.</p>
      </div>
      
      <div class="column">
        <BulmaAccordion>
          <BulmaAccordionItem v-for="item in data" :key="item.title">
            <h4 class="custom" slot="title">{{item.title}}</h4>
            <p slot="content">{{item.content}}</p>
          </BulmaAccordionItem>
        </BulmaAccordion>
      </div>
    </div>
  </section>
</template>

<script>
import { BulmaAccordion, BulmaAccordionItem } from "vue-bulma-accordion";
export default {
  components: { BulmaAccordion, BulmaAccordionItem },
  data() {
    return {
      data: [
        {
          title: "Qual tecnologia preciso para realizar os cursos?",
          content:
            "As aulas podem ser assistidas pelos navegadores Web Chrome, Firefox ou IE 9 ou mais recente. Sistema operacional Windows 7, Mac OS Snow Leopard ou superior. Caso não consiga visualizar os vídeos, atualize seu plugin flash player. Se não funcionar mesmo assim, entre em contato conosco para que possamos ajudá-lo na solução do problema."
        },
        {
          title: "Quais são as formas de pagamento?",
          content:
            "No Pagseguro você pode usar cartões de crédito, débito bancário, boleto bancário e o depósito em conta corrente."
        },
        {
          title: "O que acontece quando vence o prazo de validade de um curso?",
          content:
            "Terminado o prazo de validade de cada curso o acesso do aluno é bloqueado. Assim, não terá mais acesso às vídeo aulas, aos exercícios, nem às perguntas e respostas. Mas, continuará acessando o seu certificado de conclusão de curso."
        },
        {
          title: "Posso fazer download do material para estudar em casa?",
          content:
            "Todos os vídeos, textos e exercícios serão disponibilizados na plataforma de ensino. Serão atualizados com todas as novidades inerentes a cada curso, e você poderá acessá-los de qualquer lugar, e em qualquer instante."
        },
        {
          title: "Estou com dúvida em uma aula/exercício. O que devo fazer?",
          content:
            "Em cada vídeo aula existe uma área para cadastrar perguntas. Nessa área você conseguirá cadastrar suas dúvidas para que os instrutores da ASSBAN Cursos Online, e até mesmo outros colegas, possam contribuir para sanar suas dúvidas."
        },
        {
          title: "Existe desconto para compra de cursos no atacado?",
          content:
            "Sim, existe desconto para compra de cursos em grande quantidade, entre em contato: cursosonline@assbandf.com.br"
        },
        {
          title: "Não estou conseguindo finalizar meu pagamento. O que faço?",
          content:
            "Fale com o Departamento Financeiro: cursosonline@assbandf.com.br, que a questão será resolvida."
        },
        {
          title: "Ao comprar um curso, quantas vezes posso fazer?",
          content:
            "Você poderá refazer o curso tantas vezes quiser, durante o prazo de validade de cada curso, porque, vencida a validade do curso, o acesso será bloqueado, quando novos cursos serão disponibilizados."
        },
        {
          title: "Ao terminar um curso, recebo algum certificado?",
          content:
            "Sim, o certificado de conclusão será disponibilizado na forma digital, automaticamente, após a conclusão de cada curso."
        }
      ]
    };
  }
};
</script>

<style lang="scss" scoped>
.custom {
  cursor: pointer;
}
</style>
